import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    nombre: "",
    nombreComercio: "",
    cuit: "",
    telefono: "",
    mail: "",
  });

  // Función para verificar si hay números ascendentes
  function hasAscendingNumbers(digits) {
    let consecutiveCount = 1;

    for (let i = 0; i < digits.length - 1; i++) {
      const currentDigit = digits[i];
      const nextDigit = digits[i + 1];

      if (currentDigit === nextDigit - 1) {
        consecutiveCount++;

        if (consecutiveCount > 3) {
          return true;
        }
      } else {
        consecutiveCount = 1;
      }
    }

    return false;
  }

  // Función para verificar si hay números descendentes
  function hasDescendingNumbers(digits) {
    let consecutiveCount = 1;

    for (let i = 0; i < digits.length - 1; i++) {
      const currentDigit = digits[i];
      const nextDigit = digits[i + 1];

      if (currentDigit === nextDigit + 1) {
        consecutiveCount++;

        if (consecutiveCount > 3) {
          return true;
        }
      } else {
        consecutiveCount = 1;
      }
    }

    return false;
  }

  // Función para verificar si hay números repetidos
  function hasRepeatedNumbers(digits) {
    let repeatCount = 1;

    for (let i = 0; i < digits.length - 1; i++) {
      const currentDigit = digits[i];
      const nextDigit = digits[i + 1];

      if (currentDigit === nextDigit) {
        repeatCount++;

        if (repeatCount > 3) {
          return true;
        }
      } else {
        repeatCount = 1;
      }
    }

    return false;
  }

  const validate = (formData) => {
    let errors = {};

    if (!formData.nombre) {
      errors.nombre = "El nombre es requerido";
    }

    if (!formData.telefono) {
      errors.telefono = "El teléfono es requerido";
    } else {
      const telregex = /^\d{8,12}$/;
      if (!telregex.test(formData.telefono)) {
        errors.telefono2 = "Revise su numero telefónico";
      } else {
        const dniDigits = formData.telefono.split("").map(Number);

        if (
          // hasConsecutiveNumbers(dniDigits, 4) ||
          hasAscendingNumbers(dniDigits) ||
          hasDescendingNumbers(dniDigits) ||
          hasRepeatedNumbers(dniDigits)
        ) {
          errors.telefono3 = "Ingresá un teléfono válido";
        }
      }
    }

    if (!formData.cuit) {
      errors.cuit = "Ingresá tu CUIT";
    } else {
      const cuitreg = /^\d{8,11}$/;
      if (!cuitreg.test(formData.cuit)) {
        errors.cuit2 = "Ingresá un CUIT valido";
      }
    }

    if (!formData.mail) {
      errors.mail = "El correo electrónico es requerido";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.mail)) {
        errors.mail2 = "El correo electrónico no es válido";
      }
    }

    if (!formData.nombreComercio) {
      errors.nombreComercio = "Ingresá el nombre de tu comercio";
    }

    return errors;
  };

  useEffect(() => {
    setErrors(validate(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    if (Object.keys(errors).length > 0) {
      setShowErrors(true);
    } else {
      axios
        .post(
          "https://blackwallet.com.ar/api/emailSender/campania",
          formData
        )
        .then(function (response) {
          console.log(response.data);
          navigate("/ProyectoEnviado");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <div className="bg-black flex w-full  items-center  se:min-h-[100vh] se:h-auto xs:min-h-[100vh] lt:h-auto md:h-[100vh] flex-col">
      <div className="w-full p-2 xs:p-5 flex justify-center">
        <img
          className="w-[25%] xs:w-[8%]"
          src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1694024771/WALLET%20BLACK/Landing%20Comerciantes/Venus_sin_pastilla_blanca_1_fpdnd9.svg"
          alt="Black Wallet"
        />
      </div>

      <form
        onSubmit={handleSubmit}
        className="w-full  spro:mt-[15%]  xs:mt-0 md:mt-[2%] xs:w-[55%] flex flex-col items-center justify-center "
        autoComplete="off"
      >
        <div className=" w-[85%] lt:w-[70%] relative mt-4 xs:mt-12 mb-12 p-8 lt:p-14 text-white border rounded-xl">
          <h1 className="text-[25px] lt:text-[38px] mb-3 font-bold">
            ¡Tus ganas de crecer cuentan!
          </h1>
          <h3 className="text-[18px]">
            Completá el formulario e iniciá tu #ProyectoBlack
          </h3>

          <div className="flex flex-col z-[100] w-full mt-8">
            <div className="inputs-holder">
              <div className="w-[50%] xs:w-[100%] z-[100] relative">
                <label className="absolute bg-black top-[-0.5rem] left-[12px] px-1 se:text-[11px] spro:text-[11px] xs:text-[13px]">
                  Nombre Completo
                </label>
                <input
                  type="text"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  placeholder="Nombre |"
                  name="nombre"
                  className="bg-black z-[100] w-full border border-white rounded-lg pb-2 p-3"
                  required
                />
                {showErrors === true
                  ? errors.nombre && (
                    <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                      {errors.nombre}
                    </p>
                  )
                  : null}
              </div>

              <div className="w-[50%] xs:w-[100%] z-[100] relative">
                <label className="absolute bg-black top-[-0.5rem] left-[12px] px-1 se:text-[11px] spro:text-[11px] xs:text-[13px]">
                  Nombre del comercio
                </label>
                <input
                  type="text"
                  value={formData.nombreComercio}
                  onChange={handleInputChange}
                  placeholder="Nombre |"
                  name="nombreComercio"
                  className="bg-black z-[100]  w-full  border border-white rounded-lg pb-2 p-3"
                  required
                />
                {showErrors === true
                  ? errors.nombreComercio && (
                    <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                      {errors.nombreComercio}
                    </p>
                  )
                  : null}
              </div>
            </div>

            <div className="inputs-holder">
              <div className=" w-[50%] xs:w-[100%] z-[100] relative">
                <label className="absolute bg-black top-[-0.5rem] left-[12px] px-1 se:text-[11px] spro:text-[11px] xs:text-[13px]">
                  CUIT
                </label>
                <input
                  type="tel"
                  value={formData.cuit}
                  onChange={handleInputChange}
                  placeholder="CUIT |"
                  name="cuit"
                  className="bg-black z-[100] w-full  border border-white rounded-lg pb-2 p-3"
                  minLength={10}
                  maxLength={11}
                  required
                />
                {showErrors === true
                  ? errors.cuit && (
                    <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                      {errors.cuit}
                    </p>
                  )
                  : null}
                {showErrors === true
                  ? errors.cuit2 && (
                    <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                      {errors.cuit2}
                    </p>
                  )
                  : null}
              </div>

              <div className="w-[50%] xs:w-[100%] z-[100] relative">
                <label className="absolute bg-black top-[-0.5rem] left-[12px] px-1 se:text-[11px] spro:text-[11px] xs:text-[13px]">
                  Teléfono personal
                </label>
                <input
                  type="tel"
                  value={formData.telefono}
                  onChange={handleInputChange}
                  placeholder="Teléfono personal (sin 0 y sin 15) |"
                  name="telefono"
                  className="bg-black z-[100]  w-full  border border-white rounded-lg pb-2 p-3"
                  minLength={10}
                  maxLength={10}
                  required
                />
                {showErrors === true
                  ? errors.telefono && (
                    <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                      {errors.telefono}
                    </p>
                  )
                  : null}
                {showErrors === true
                  ? errors.telefono2 && (
                    <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                      {errors.telefono2}
                    </p>
                  )
                  : null}
                {showErrors === true
                  ? errors.telefono3 && (
                    <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                      {errors.telefono3}
                    </p>
                  )
                  : null}
              </div>
            </div>

            <div className="relative z-[100]">
              <label className="absolute bg-black top-[-0.5rem] left-[12px] px-1 se:text-[11px] spro:text-[11px] xs:text-[13px]">
                Tu Mail
              </label>
              <input
                type="email"
                value={formData.mail}
                onChange={handleInputChange}
                placeholder="Tu Mail |"
                name="mail"
                className="bg-black z-[100]  w-full  border border-white rounded-lg pb-2 p-3"
                required
              />
              {showErrors === true
                ? errors.mail && (
                  <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                    {errors.mail}
                  </p>
                )
                : null}
              {showErrors === true
                ? errors.mail2 && (
                  <p style={{ color: "red", fontSize: "10px", marginTop: 4 }}>
                    {errors.mail2}
                  </p>
                )
                : null}
            </div>

            <div className="w-full mt-[9%]  flex justify-end">
              <button className="bg-[#3C3C3C] p-3 w-[35%] xs:w-[25%] md:w-[17%] hover:bg-[#16AC7D] transition-all duration-300 rounded-3xl">
                Enviar
              </button>
            </div>
          </div>

          <img
            className="absolute left-0 w-[35%] z-[10] bottom-0"
            src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693502010/WALLET%20BLACK/Landing%20beneficios/Hero/Capa_1_kyybap.svg"
            alt="bg"
          />
        </div>
      </form>
    </div>
  );
};

export default Landing;